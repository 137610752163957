/* eslint-disable react/no-unescaped-entities */
import {React} from "react";
import YouTube from "react-youtube";
import   "./styles.css";
import  background from "../images/backgrounds/front_yard_1.png"
import  firstSection from "../images/backgrounds/FirstSection_20230312_173651.jpg"
import TextImage from "../Common/TextImage";
import ImageText from "../Common/ImageText"
import orgDfly from "../images/dragonflies/dragonfly_orange_small.jpg";
import  firstSection0523 from "../images/plants/FirstSectionMay_20230513_101635.png"



const Home = () => {

     return (
<div style={{background:"black"}}>
      <div className={`greenFancyHeadFontCenter`} >
          <h1 >the Neighbors' Yard</h1>
          <h2>Oasis in an urban desert</h2>
          <div className={`blackBodyFontCenter`} style={{backgroundImage:`url('${background}')`, backgroundRepeat:"no-repeat",backgroundSize:"cover" , width:'100vw',height:'100vh'}}/>
            <div>
              Doing our little bit to help the world be a better place
            </div>
          </div>
      <div>
     
      <div style={{display:"flex",alignItems:"center", width:"auto", margin:"auto"}}>
        <ImageText 
          selImage={firstSection}
          myText="In the Neighbors' Yard, we started out hating to mow grass all the time.|Watering during the long hot summer of Texas was ridiculously costly.|
          So, what could we do?|
          We decided that we wanted to replace our lawn with native plants and make a butterfly garden.|
          That was going to be expensive and required a lot of decisions to be made all at once.|
          Then we realized that it didn't have to be done instantly.|
          We could take our time, start with a small area and try native Texas plants to see which worked for us esthetically and for survivability.||
          Our first year, we actually created a haven for native bees and dragonflies and butterflies and insects galore.|
          With one ENORMOUS exception......|......MOSQUITOES......|......our mosquito population dropped to near zero.|
          In Texas, that is BIG deal.||
          What caused this?|
          Dragonflies and damselflies.|
          When we added a small pond purchased at our local DIY store (24 in diameter, 18 in deep), they came and we saw immediate benefits.|
          After some study, we found that a dragonfly/damselfly garden attracts all pollinators.|
          So, we updated our plans to be directed towards making a dragonfly/damselfly haven.| 
          Now, we are continuing to expand and we will document our efforts and effects here so everyone can benefit from our work."
            />
      </div>

      <div style={{display:"flex",alignItems:"center", width:"auto", margin:"auto"}}>
        <TextImage 
          selImage={firstSection0523}
          myText="As you can see, in just a couple of months our garden started to fill in.|
                  Soon, it was a wonderland of nature."
            />
      </div>
      <div style={{display:"flex",alignItems:"center", width:"auto", margin:"auto"}}>
        <ImageText 
          selImage={orgDfly}
          myText="Make a difference in any way you can.||
                  Changing your lawn to a native plant wonderland won't fix climate change.||
                  But if all 85 million lawns in the US convert to native gardens,|it will make a huge dent.|||
                  The Journey of a Thousand Miles begins with a single step.|||
                  Won't you step along with us?||
                  ||Peruse our pages, navigate our net and wow at the wonders our garden has brought to our home.|
                  You may even get some tips on what we have done (and from our trials and errors).||
                  And, most of all, enjoy what our world has to offer."
            />
      </div>
          <YouTube
          videoId="fwCri_j9Un4"
          id="fwCri_j9Un4"
          title="Monarch caterpiller lunch" 
          allowfullscreen />
        </div>
        </div>
     )
   };
   
   export default Home;