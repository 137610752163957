/* eslint-disable no-undef */
import React from "react";
import CarouselGarden from "../Common/CarouselGarden";

const insImages = require.context('../images/genInsects',true);
const imageList = insImages.keys().map(image => insImages(image));




const InsectGallery = () => {


const insectImgs = imageList.map((imageID) => {
     return {
    src: imageID,
    thumbnail: imageID
  };
})


     return (
          <CarouselGarden images={insectImgs} 
               pageTitle="Our Other Insect visitors" 
               pageText="Our insect visitors are many and various."
          />
     );
}

export default InsectGallery;