/* eslint-disable react/no-unescaped-entities */
import {React,useState,useEffect} from "react";

import "../Navbar.css";
import "../pages/styles.css";
import { NavLink } from "react-router-dom";
import  Modal  from "@mui/material/Modal/Modal";
import  bfliesImg from "../images/backgrounds/bflies.gif";
import  dgnfliesImg from "../images/backgrounds/dgnflies.gif";
import  dmslfliesImg from "../images/backgrounds/bflies.gif";
import  bugsImg from "../images/backgrounds/bugs5.gif";
import  yrdImg from "../images/backgrounds/bflies.gif";


export default function Nav(){
     const [showTesting,setShowTesting] = useState(false);
     const [imgSrc,setImgSrc] = useState();

     useEffect(() => {
          if(showTesting)  {
               const timer = setTimeout(() => {
                    setShowTesting(!showTesting);
               },2000);
               return () => clearTimeout(timer);
          }
     }, [showTesting]);



     const handleTestingClick = (newImg) => {
          setImgSrc(newImg)
          setShowTesting(true)
     }

  return(
        <div className="navbar">
          <div className="blackFancyHeadFontCenter">the Neighbors' Yard</div>
               <ul className="nav-links" >
                    <NavLink to="/" className={({ isActive }) =>
                                                  isActive ? 'nav-links active' : 'nav-links'
                                                  }>
                                             <button  className={`whiteFancyHeadFontCenter`} style={{backgroundColor:'#05b025',border:'0'}} >
                                                  Home 
                                             </button>
                    </NavLink>
                    <NavLink to="/blogs" className={({ isActive }) =>
                                                  isActive ? 'nav-links active' : 'nav-links'
                                                  }>
                                             <button className={`whiteFancyHeadFontCenter`} style={{backgroundColor:'#05b025',border:'0'}} >
                                                  Blogs 
                                             </button>
                    </NavLink>

                    <div className="dropdown">
                         <button className={"dropbutton"+ " " + `whiteFancyHeadFontCenter`}>Galleries</button>
                         <div className="dropdown-content">
                              <NavLink to="/butterflies" className={({ isActive }) =>
                                                  isActive ? 'nav-links active' : 'nav-links'
                                                  }>
                                             <button className={`whiteFancyHeadFontCenter`} onClick={()=> handleTestingClick(bfliesImg)} type="button" style={{backgroundColor:'#05b025',border:'0'}} >
                                                  Butterflies 
                                             </button>
                              </NavLink>
                              <NavLink to="/dragonflies" className={({ isActive }) =>
                                                  isActive ? 'nav-links active' : 'nav-links'
                                                  }>
                                             <button className={`whiteFancyHeadFontCenter`} onClick={()=> handleTestingClick(dgnfliesImg)} type="button" style={{backgroundColor:'#05b025',border:'0'}} >
                                                  Dragonflies 
                                             </button>
                              </NavLink>
                              
                              <NavLink to="/otherinsects" className={({ isActive }) =>
                                                  isActive ? 'nav-links active' : 'nav-links'
                                                  }>
                                             <button className={`whiteFancyHeadFontCenter`} onClick={()=> handleTestingClick(bugsImg)} type="button" style={{backgroundColor:'#05b025',border:'0'}} >
                                                  Other Insects 
                                             </button>
                              </NavLink>
                              <NavLink to="/othervisitors" className={({ isActive }) =>
                                                  isActive ? 'nav-links active' : 'nav-links'
                                                  }>
                                             <button className={`whiteFancyHeadFontCenter`} onClick={()=> handleTestingClick(dmslfliesImg)} type="button" style={{backgroundColor:'#05b025',border:'0'}} >
                                                  Other visitors 
                                             </button>
                              </NavLink>
                              <NavLink to="/yardevolve" className={({ isActive }) =>
                                                  isActive ? 'nav-links active' : 'nav-links'
                                                  }>
                                             <button className={`whiteFancyHeadFontCenter`} onClick={()=> handleTestingClick(yrdImg)} type="button" style={{backgroundColor:'#05b025',border:'0'}} >
                                                  Yard Evolution 
                                             </button>
                              </NavLink>
                         </div>
                    </div>
               </ul>
               <Modal
                    open={showTesting}
               >
                <img src={imgSrc} style={{display:"flex",alignItems:"center",width:'100vw',height:'100vh'}}/>
               </Modal>
        </div>
        
  );

}