/* eslint-disable react/no-unescaped-entities */
import {React} from "react";
import styles from "./styles.css";

const ImportTest = () => { 
     return(
          <div>



                         <h1 className={styles }>
                              The Neighbors' Yard
                         </h1>

                    </div>
     )
}
export default ImportTest;