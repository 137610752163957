/* eslint-disable no-undef */
import React from "react";
import CarouselGarden from "../Common/CarouselGarden";

const bflyImages = require.context('../images/butterflies',true);
const imageList = bflyImages.keys().map(image => bflyImages(image));




const ButterfliesGallery = () => {


const butterflyImgs = imageList.map((imageID) => {
     return {
    src: imageID,
    thumbnail: imageID
  };
})



     // let butterflyImgs = [{src: '../src/images/butterflies/IMG_0532.JPG'},
     //       {src: '../src/images/butterflies/IMG_0534.JPG'}]
          //  ,
          //  '../src/images/butterflies/IMG_0536.JPG',
          //  '../src/images/butterflies/IMG_0669.JPG',
          //  '../src/images/butterflies/IMG_0670.JPG',
          //  '../src/images/butterflies/IMG_0722.JPG',
          //  '../src/images/butterflies/IMG_0776.JPG',
          //  '../src/images/butterflies/IMG_0784.JPG',
          //  '../src/images/butterflies/IMG_0785.JPG',
          //  '../src/images/butterflies/IMG_0788.JPG',
          //  '../src/images/butterflies/IMG_0789.JPG',
          //  '../src/images/butterflies/IMG_0790.JPG',
          //  '../src/images/butterflies/IMG_0792.JPG',
          //  '../src/images/butterflies/IMG_0793.JPG',
          //  '../src/images/butterflies/IMG_0795.JPG',
          //  '../src/images/butterflies/IMG_0796.JPG',
          //  '../src/images/butterflies/IMG_0797.JPG',
          //  '../src/images/butterflies/IMG_0799.JPG',
          //  '../src/images/butterflies/IMG_0806.JPG',
          //  '../src/images/butterflies/IMG_0807.JPG',
          //  '../src/images/butterflies/IMG_0808.JPG',
          //  '../src/images/butterflies/IMG_0809.JPG',
          //  '../src/images/butterflies/IMG_0810.JPG',
          //  '../src/images/butterflies/IMG_0812.JPG',
          //  '../src/images/butterflies/IMG_0820.JPG',
          //  '../src/images/butterflies/IMG_0821.JPG',
          //  '../src/images/butterflies/IMG_0822.JPG',
          //  '../src/images/butterflies/IMG_0823.JPG'];

     //      require(listFiles);
     // butterflyImgs(function (result){
     //      console.log(result);
     // }, {
     //      dir: "../images/butterflies"
     // })

     // let butterflyImgs = listFiles({dir: "../images/butterflies"});
     return (
          <CarouselGarden images={butterflyImgs} 
               pageTitle="Our butterfly visitors" 
               pageText="While we try to attract Monarchs and Swallowtails, we get many other butterfly visitors."
               style={{justifyContent:'center'}}
          />
     );
}

export default ButterfliesGallery;