/* eslint-disable react/prop-types */
import {React} from "react";
import   "../pages/styles.css";


export default function TextImage(props) {
    const selImage =props.selImage;
    const myText = props.myText.split("|");

     return (
          <div className="container" style={{display:"flex",alignItems:"center",background:"black",whiteSpace:"pre-wrap",padding:"50px", width:"auto", margin:"auto"}}>
               <img src={selImage} style={{display:"flex",alignItems:"center",background:"black",width:'50em',height:'50vh',padding:"20px"}}/>
               
               <div className="container" style={{display:"grid",alignItems:"start",padding:"20px"}} >
                    {myText.map(function(data){
                              if(data){
                                   return (
                                        <div key={data.id} className={`greenBodyFontCenter`} style={{display:"grid",alignItems:"left",background:"black"}}>{data}</div>
                                   )
                              }else{
                                   return(
                                        <div key={data.id} >{" "}</div>
                                   )
                              }
                              }
                         )
                    }
               </div>
          </div>
     )
}