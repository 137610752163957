import { React,StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './App';
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Blogs from "./pages/Blogs";
import NoPage from "./pages/NoPage";
import ButterfliesGallery from "./pages/ButterfliesGalleryPage";
import DragonfliesGallery from "./pages/DragonfliesGalleryPage";
import InsectGallery from "./pages/InsectsGalleryPage";
import VisitorGallery from "./pages/VisitorGalleryPage";
import ImportTest from "./pages/test";
import Navbar from './Common/Navbar';


// import reportWebVitals from './reportWebVitals';

export default function App() {
  return (
    <BrowserRouter>
    <div className='App'>
      <Navbar />
      <Routes>
          <Route path="/" index element={<Home />} />
          <Route path="blogs" element={<Blogs />} />
          <Route path="test" element={<ImportTest/>} />
          <Route path="/butterflies" element={<ButterfliesGallery />} />
          <Route path="/dragonflies" element={<DragonfliesGallery />} />
          <Route path="/otherinsects" element={<InsectGallery />} />
          <Route path="/othervisitors" element={<VisitorGallery />} />
          
          
          <Route path="*" element={<NoPage />} />

      </Routes>
      </div>
    </BrowserRouter>
  );
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <StrictMode>
    <App/>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
