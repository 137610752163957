/* eslint-disable react/prop-types */
import React from 'react';
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
import   "../pages/styles.css";

const CarouselGarden = ({ images, pageTitle,pageText }) => {
  return (
    <section className='galleryCenter'   >
      <header className='section-header'>
        <h2 id='pageTitle'>
          {pageTitle}
        </h2>
        <p>
         {pageText}
        </p>
      </header>
      <div className='carousel-container'>
        <Carousel images={images} style={{width:'60vw',height:'70vh'}} />
      </div>
    </section>
  );
};

export default CarouselGarden;