/* eslint-disable no-undef */
import React from "react";
import CarouselGarden from "../Common/CarouselGarden";

const visitImages = require.context('../images/otherVisitors',true);
const imageList = visitImages.keys().map(image => visitImages(image));




const VisitorGallery = () => {


const visitorImgs = imageList.map((imageID) => {
     return {
    src: imageID,
    thumbnail: imageID
  };
})


     return (
          <CarouselGarden images={visitorImgs} 
               pageTitle="Our Other visitors" 
               pageText="Here are some of the many visitors to our oasis that are not insects"
          />
     );
}

export default VisitorGallery;