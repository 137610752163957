/* eslint-disable no-undef */
import React from "react";
import CarouselGarden from "../Common/CarouselGarden";

const dflyImages = require.context('../images/dragonflies',true);
const imageList = dflyImages.keys().map(image => dflyImages(image));




const DragonfliesGallery = () => {


const dragonflyImgs = imageList.map((imageID) => {
     return {
    src: imageID,
    thumbnail: imageID
  };
})


     return (
          <CarouselGarden images={dragonflyImgs} 
               pageTitle="Our dragonfly visitors" 
               pageText="Some images of our beautiful dragonfly visitors."
          />
     );
}

export default DragonfliesGallery;